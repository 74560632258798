
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons/faArrowUp'
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars'
import { faCamera } from '@fortawesome/free-solid-svg-icons/faCamera'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons/faCaretRight'
import { faChartBar } from '@fortawesome/free-solid-svg-icons/faChartBar'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons/faCheckSquare'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp'
import { faClipboard } from '@fortawesome/free-solid-svg-icons/faClipboard'
import { faColumns } from '@fortawesome/free-solid-svg-icons/faColumns'
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy'
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons/faFileAlt'
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter'
import { faGlobe } from '@fortawesome/free-solid-svg-icons/faGlobe'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle'
import { faMinusSquare } from '@fortawesome/free-solid-svg-icons/faMinusSquare'
import { faSave } from '@fortawesome/free-solid-svg-icons/faSave'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { faShareAlt } from '@fortawesome/free-solid-svg-icons/faShareAlt'
import { faShareSquare } from '@fortawesome/free-solid-svg-icons/faShareSquare'
import { faSortAlphaDown } from '@fortawesome/free-solid-svg-icons/faSortAlphaDown'
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner'
import { faSquare } from '@fortawesome/free-solid-svg-icons/faSquare'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt'
import { faUserEdit } from '@fortawesome/free-solid-svg-icons/faUserEdit'
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons/faQuoteLeft'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUndo } from '@fortawesome/free-solid-svg-icons/faUndo'
import { faPen } from '@fortawesome/free-solid-svg-icons/faPen'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus'
import {
  // Part of AlertPlugin
  BAlert,
  // Part of BadgePlugin
  BBadge,
  // Part of ButtonPlugin
  BButton, BButtonGroup,
  // Part of CardPlugin
  BCard, BCol,
  // Part of CollapsePlugin
  BCollapse,
  // Part of LayoutPlugin
  BContainer,
  // Part of DropdownPlugin
  BDropdown, BDropdownItem,
  // Part of FormPlugin
  BForm,
  // Part of FormCheckboxPlugin
  BFormCheckbox,
  // Part of FormGroupPlugin
  BFormGroup,
  // Part of FormInputPlugin
  BFormInput, BFormRadio,
  BFormRadioGroup,
  // Part of FormSelectPlugin
  BFormSelect,
  // Part of InputGroupPlugin
  BFormTags,
  //
  BFormTextarea,
  // Part of ImagePlugin
  BImg, BInputGroup, BInputGroupAppend, BInputGroupPrepend, BLink,
  // Part of ListGroupPlugin
  BListGroup, BListGroupItem,
  // Part of ModalPlugin
  BModal,
  // Part of NavPlugin
  BNav,
  // Part of NavbarPlugin
  BNavbar, BNavbarBrand, BNavbarNav, BNavbarToggle, BNavItem, BNavItemDropdown,
  // Part of PaginationPlugin
  BPagination, BRow,
  // Part of SpinnerPlugin
  BSpinner, BTab,
  // Part of TablePlugin
  BTable, BTableSimple,
  // Part of TabsPlugin
  BTabs, BTbody, BTd, BTh, BThead, BTr,
  // DIRECTIVES
  VBToggle,
  //
  BFormInvalidFeedback,
  BFormDatepicker,
} from 'bootstrap-vue'
import VueMathjax from 'vue-mathjax'
import { Component, Vue } from 'vue-property-decorator'
import Layout from './components/layout/Layout.vue'
import VeeValidate from 'vee-validate'
import VueCookies from 'vue-cookies'
import Croppa from 'vue-croppa'
import { MRRangeDirective } from 'mr-vue-components'
Vue.directive('range', MRRangeDirective)

library.add(
  faChartBar, faColumns, faInfoCircle, faSearch,
  faArrowUp, faArrowDown, faTimes, faChevronDown, faCalendarAlt,
  faChevronUp, faCheckSquare, faMinusSquare, faSquare, faSortAlphaDown,
  faFileAlt, faClipboard, faCopy, faDownload, faBars, faGlobe,
  faFilter, faTrash, faSave, faSpinner, faCaretRight, faCaretDown,
  faUserEdit, faShareAlt, faShareSquare, faExternalLinkAlt, faCamera, faUndo,
  faQuoteLeft, faExclamationCircle, faPen, faPlus, faMinus,
)

// Part of LayoutPlugin
Vue.component('BContainer', BContainer)
Vue.component('BRow', BRow)
Vue.component('BCol', BCol)
// Part of ButtonPlugin
Vue.component('BButton', BButton)
Vue.component('BButtonGroup', BButtonGroup)
// Part of TabsPlugin
Vue.component('BTabs', BTabs)
Vue.component('BTab', BTab)
// Part of NavbarPlugin
Vue.component('BNavbar', BNavbar)
Vue.component('BNavbarBrand', BNavbarBrand)
Vue.component('BNavbarNav', BNavbarNav)
Vue.component('BNavbarToggle', BNavbarToggle)
// Part of NavPlugin
Vue.component('BNav', BNav)
Vue.component('BNavItem', BNavItem)
Vue.component('BNavItemDropdown', BNavItemDropdown)
// Part of CardPlugin
Vue.component('BCard', BCard)
// Part of FormSelectPlugin
Vue.component('BFormSelect', BFormSelect)
// Part of DropdownPlugin
Vue.component('BDropdown', BDropdown)
Vue.component('BDropdownItem', BDropdownItem)
// Part of FormPlugin
Vue.component('BForm', BForm)
//
Vue.component('BFormTextarea', BFormTextarea)
// Part of FormGroupPlugin
Vue.component('BFormGroup', BFormGroup)
// Part of FormCheckboxPlugin
Vue.component('BFormCheckbox', BFormCheckbox)
// Part of FormInputPlugin
Vue.component('BFormInput', BFormInput)
// Part of InputGroupPlugin
Vue.component('BFormTags', BFormTags)
Vue.component('BFormRadio', BFormRadio)
Vue.component('BFormRadioGroup', BFormRadioGroup)
Vue.component('BInputGroup', BInputGroup)
Vue.component('BInputGroupAppend', BInputGroupAppend)
Vue.component('BInputGroupPrepend', BInputGroupPrepend)
// Part of ModalPlugin
Vue.component('BModal', BModal)
// Part of SpinnerPlugin
Vue.component('BSpinner', BSpinner)
// Part of CollapsePlugin
Vue.component('BCollapse', BCollapse)
// Part of ImagePlugin
Vue.component('BImg', BImg)
// Part of ListGroupPlugin
Vue.component('BListGroup', BListGroup)
Vue.component('BListGroupItem', BListGroupItem)
// Part of PaginationPlugin
Vue.component('BPagination', BPagination)
// Part of BadgePlugin
Vue.component('BBadge', BBadge)
// Part of TablePlugin
Vue.component('BTable', BTable)
Vue.component('BTableSimple', BTableSimple)
Vue.component('BThead', BThead)
Vue.component('BTbody', BTbody)
Vue.component('BTr', BTr)
Vue.component('BTh', BTh)
Vue.component('BTd', BTd)
Vue.component('BFormInvalidFeedback', BFormInvalidFeedback)
Vue.component('BFormDatepicker', BFormDatepicker)

Vue.component('BAlert', BAlert)
Vue.component('BLink', BLink)
Vue.component('FontAwesomeIcon', FontAwesomeIcon)

Vue.directive('b-toggle', VBToggle)

Vue.use(Croppa)
Vue.use(VueMathjax)
Vue.use(VueCookies, { expires: -1 }) // Set to never expire, since it's being used for pinned queries... turns out that "never" means about a year

Vue.use(VeeValidate, {
  // This is the default
  inject: true,
  // Important to name this something other than 'fields'
  fieldsBagName: 'veeFields',
  // This is not required but avoids possible naming conflicts
  errorBagName: 'veeErrors',
})

// Vue.prototype.$http = axios.create({
//   baseURL: process.env.BASE_URL,
// })

@Component({
  components: {
    Layout,
  },
})
export default class App extends Vue {
  //
  // LIFECYCLE HOOKS
  //

  mounted() {
    // Force scroll to alway go to the top when the page is refresh
    if (history.scrollRestoration) {
      history.scrollRestoration = 'manual'
    } else {
      window.onbeforeunload = () => {
        window.scrollTo(0, 0)
      }
    }

    window.goToArticle = this.goToArticle.bind(this)
    window._goToArticle = this.goToArticle.bind(this)
  }

  //
  // METHODS
  //
  goToArticle(mr: number) {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera

    this.$router
      .push({ name: 'ArticlePage', query: { mr: mr.toString() } })
      .catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          throw err
        }
      })
  }
}
