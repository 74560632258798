import axios from 'axios'
import Vue from 'vue'
// import router from '@/router'

Vue.prototype.$http = process.env.BASE_URL
  ? axios.create({
    baseURL: process.env.BASE_URL,
  })
  : axios

const http = Vue.prototype.$http

http.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      window.location.href = 'unauthorized-access'
      // TODO: I would like to use this but it is breaking the test for the mock of $route and $router
      // router.push({ name: 'UnauthorizedAccess' })
    }

    return Promise.reject(error)
  }
)

export async function getResult<T>(
  url: string,
  params: unknown | undefined = {},
  controller?: AbortController,
): Promise<T> {
  const { data } = await http
    .get(url, { params, signal: controller?.signal })
    .catch((error) => {
      if (axios.isCancel(error)) {
        return { data: null }
      } else {
        throw error
      }
    })

  return data
}

export async function post<T>(
  url: string,
  payload: unknown | undefined,
): Promise<T> {
  const { data } = await http.post(url, payload)

  return data
}

export async function postForm<T>(
  url: string,
  formData: FormData,
): Promise<T> {
  const { data } = await http.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return data
}
