import { AxiosError } from 'axios'
import Vue from 'vue'
import { AMSAccountInfo } from '@/api/types'

const http = Vue.prototype.$http

const redirectToAMSAccountLogin = async(redirectUrl: string, contextPath?: string) => {
  // const origin = window.location.origin
  // const path = window.location.pathname.split('/')[1]
  const url = `page/ams/login?redirectUrl=${encodeURIComponent(redirectUrl)}`

  // window.location.href = `${origin}/${path}${url}`

  if (contextPath) {
    window.location.href = `${origin}${contextPath}/${url}`
  } else {
    window.location.href = url
  }
}

export const AMSAccountAPI = {

  async isLoggedIn(): Promise<boolean> {
    const { data } = await http.get('/api/ams/is-logged-in')

    return data
  },

  redirectToAMSLogin(redirectUrl: string, contextPath?: string): void {
    redirectToAMSAccountLogin(redirectUrl, contextPath)
  },

  isUnauthorized(error: unknown): boolean {
    try {
      const _error = error as AxiosError

      if (_error.response && _error.response.status) {
        if (_error.response.status === 401) {
          return true
        }
      }
    } catch {}

    return false
  },

  async getAMSAccountInfo(): Promise<AMSAccountInfo> {
    const { data } = await http.get('/api/ams/account-info')

    return data
  },

}
