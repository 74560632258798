import { AppConfig, PairingInfo } from './types'
import { getResult } from './utils'

export const AppConfigAPI = {

  async getAppConfig(): Promise<AppConfig> {
    return getResult('/api/app-config')
  },

  async getPairingInfo(): Promise<PairingInfo> {
    return getResult('/page/ams/pairing-info')
  },

}
