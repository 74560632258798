import Vue from 'vue'
import Vuex, { Commit, StoreOptions } from 'vuex'
import { Results, SearchParams } from '@/global-types'
import { Publication } from '@/components/search-results/publications-results'
import { PublicationsAPI } from './api'
import { AppConfig } from './api/types'

Vue.use(Vuex)

//
// TYPES
//

type PagedPublications = {
  pageNumber: number
  publications: Publication[]
}

export enum LastSearchTypeOfItem {
  PUBLICATION = 'publications',
  AUTHOR = 'authors',
  JOURNAL = 'journals',
  SERIES = 'series',
}

type LastSearchState = {
  typeOfItem: LastSearchTypeOfItem
  notMatchingResults: boolean
  hasErrors: boolean
  errorMessage: string
  shouldResetSearchParams: boolean
  isExpandedSearch: boolean
}

type State = {
  searchParams: SearchParams
  totalPublications: number
  data: PagedPublications[]
  selectedPublicationId: number
  publicationsSearchText: string
  authorsSearchText: string
  journalsSearchText: string
  seriesSearchText: string
  appConfig: AppConfig
  elScrollTo?: string
  lastSearchState: LastSearchState,
}

type UpdatePrevNextPubsParams = {
  searchParams: SearchParams
  results: Results<Publication>
}

//
// MUTATION KEYS
//
const UPDATE_SEARCH_PARAMS = 'UPDATE_SEARCH_PARAMS'
const UPDATE_TOTAL_PUBLICATIONS = 'UPDATE_TOTAL_PUBLICATIONS'
const UPDATE_DATA = 'UPDATE_DATA'
const UPDATE_SELECTED_PUBLICATION_ID = 'UPDATE_SELECTED_PUBLICATION_ID'
export const UPDATE_PUBLICATION_SEARCH_TEXT = 'UPDATE_PUBLICATION_SEARCH_TEXT'
export const UPDATE_AUTHORS_SEARCH_TEXT = 'UPDATE_AUTHORs_SEARCH_TEXT'
export const UPDATE_JOURNALS_SEARCH_TEXT = 'UPDATE_JOURNALS_SEARCH_TEXT'
export const UPDATE_SERIES_SEARCH_TEXT = 'UPDATE_SERIES_SEARCH_TEXT'
export const UPDATE_APP_CONFIG = 'UPDATE_APP_CONFIG'
export const UPDATE_EL_SCROLL_TO = 'UPDATE_EL_SCROLL_TO'
export const UPDATE_LAST_SEARCH_NOT_MATCHING = 'UPDATE_LAST_SEARCH_NOT_MATCHING'
export const UPDATE_LAST_SEARCH_HAS_ERRORS = 'UPDATE_LAST_SEARCH_HAS_ERRORS'
export const UPDATE_LAST_SEARCH_ERROR_MESSAGE = 'UPDATE_LAST_SEARCH_ERROR_MESSAGE'
export const UPDATE_LAST_SEARCH_TYPE_OF_ITEM = 'UPDATE_LAST_SEARCH_TYPE_OF_ITEM'
export const UPDATE_LAST_SEARCH_RESET_SEARCHPARAMS = 'UPDATE_LAST_SEARCH_RESET_SEARCHPARAMS'
export const UPDATE_LAST_SEARCH_EXPANDED_SEARCH = 'UPDATE_LAST_SEARCH_EXPANDED_SEARCH'

//
// ACTION KEYS
//

export const UPDATE_PREV_NEXT_PUBS_STATE = 'UPDATE_PREV_NEXT_PUBS_STATE'
export const UPDATE_PREV_NEXT_PUB_ID = 'UPDATE_PREV_NEXT_PUB_ID'
export const RESET_PREV_NEXT_STATE = 'RESET_PREV_NEXT_STATE'
export const RESET_LAST_SEARCH_STATE = 'RESET_LAST_SEARCH_STATE'
export const RESET_LAST_SEARCH_SEARCHPARAMS = 'RESET_LAST_SEARCH_SEARCHPARAMS'

//
// UTILS
//

const mergeResults = (
  publications: Publication[],
  data: PagedPublications[],
  currentPageNumber: number,
  loadedPageNumber: number,
) => {
  return loadedPageNumber > currentPageNumber
    ? [...data, { pageNumber: loadedPageNumber, publications }]
    : [{ pageNumber: loadedPageNumber, publications }, ...data]
}

const getPagedPublicationInfo = (publicationId: number, data: PagedPublications[]) => {
  for (let pagedPubIndex = 0; pagedPubIndex < data.length; pagedPubIndex++) {
    const pagedPub = data[pagedPubIndex]
    const publicationIndex = pagedPub.publications.findIndex(publication => publication.mrnumber === publicationId)

    if (publicationIndex !== -1) {
      return {
        pagedPubIndex,
        pagedPub,
        publicationIndex,
      }
    }
  }

  return {
    pagedPubIndex: -1,
    pagedPub: undefined,
    publicationIndex: -1,
  }
}

const getPreviousPublicationInfo = (currentPublicationId: number, data: PagedPublications[]) => {
  const { publicationIndex, pagedPubIndex } = getPagedPublicationInfo(currentPublicationId, data)

  if (publicationIndex !== -1 && pagedPubIndex !== -1) {
    const previousPage = data[pagedPubIndex - 1]

    if (publicationIndex === 0) {
      if (previousPage !== undefined) {
        return {
          publicationId: previousPage.publications[previousPage.publications.length - 1].mrnumber,
          pageNumber: previousPage.pageNumber,
        }
      } else {
        return {
          publicationId: undefined,
          pageNumber: data[pagedPubIndex].pageNumber - 1,
        }
      }
    }

    return {
      publicationId: data[pagedPubIndex].publications[publicationIndex - 1].mrnumber,
      pageNumber: data[pagedPubIndex].pageNumber,
    }
  }

  return {}
}

const getNextPublicationInfo = (currentPublicationId: number, data: PagedPublications[]) => {
  const { publicationIndex, pagedPubIndex, pagedPub } = getPagedPublicationInfo(currentPublicationId, data)

  if (publicationIndex !== -1 && pagedPub !== undefined) {
    const nextPage = data[pagedPubIndex + 1]

    if (publicationIndex === pagedPub.publications.length - 1) {
      if (nextPage !== undefined) {
        return {
          publicationId: nextPage.publications[0].mrnumber,
          pageNumber: nextPage.pageNumber,
        }
      } else {
        return {
          publicationId: undefined,
          pageNumber: data[pagedPubIndex].pageNumber + 1,
        }
      }
    }

    return {
      publicationId: data[pagedPubIndex].publications[publicationIndex + 1].mrnumber,
      pageNumber: data[pagedPubIndex].pageNumber,
    }
  }

  return {}
}

const fetchData = async(
  searchParams: SearchParams,
) => {
  const facetsString = searchParams.facets ? searchParams.facets : ''

  return PublicationsAPI.search(
    `${searchParams.query} ${facetsString}`.trim(),
    searchParams.pageNumber,
    searchParams.pageSize,
    searchParams.sortBy ? searchParams.sortBy : 'newest',
  )
}

const fetchAndUpdatePrevPageData = async(
  commit: Commit,
  state: State
) => {
  // Fetch data if previous publication id is undefined and current page is > 1
  const { publicationId, pageNumber } = getPreviousPublicationInfo(state.selectedPublicationId, state.data)

  if (publicationId === undefined && state.searchParams.pageNumber > 1 && pageNumber) {
    const publications = await fetchData({ ...state.searchParams, pageNumber })

    if (publications.results.length > 0) {
      const newData = mergeResults(publications.results, state.data, state.searchParams.pageNumber, pageNumber)

      commit('UPDATE_DATA', newData)
    }
  }
}

const fetchAndUpdateNextPageData = async(
  commit: Commit,
  state: State
) => {
  // Fetch data if next publication id is undefined
  const { publicationId, pageNumber } = getNextPublicationInfo(state.selectedPublicationId, state.data)

  if (publicationId === undefined && pageNumber) {
    const publications = await fetchData({ ...state.searchParams, pageNumber })

    if (publications.results.length > 0) {
      const newData = mergeResults(publications.results, state.data, state.searchParams.pageNumber, pageNumber)

      commit('UPDATE_DATA', newData)
    }
  }
}

//
// STORE MODULE
//
const initialState: State = {
  searchParams: {
    query: '',
    pageNumber: 1,
    pageSize: 20,
    sortBy: '',
    facets: '',
  },
  totalPublications: 0,
  data: [],
  selectedPublicationId: -1,
  publicationsSearchText: '',
  authorsSearchText: '',
  journalsSearchText: '',
  seriesSearchText: '',
  appConfig: {
    contextPath: '',
    remoteAccessURL: '',
    internal: false,
    customerName: '',
    isDev: false,
    isAmsDay: false,
    clientIP: '',
    serverIP: '',
    restAPIDocsURL: '',
  },
  elScrollTo: undefined,
  lastSearchState: {
    typeOfItem: LastSearchTypeOfItem.PUBLICATION,
    notMatchingResults: false,
    hasErrors: false,
    errorMessage: '',
    shouldResetSearchParams: false,
    isExpandedSearch: false,
  },
}

export const ElScrollToModule: StoreOptions<State> = {
  state: () => ({ ...initialState }),
  getters: {
    elScrollTo: (state) => {
      return state.elScrollTo
    },
  },
  mutations: {
    [UPDATE_EL_SCROLL_TO](state, elScrollTo: string) {
      state.elScrollTo = elScrollTo
    },
  },
}

export const PrevNextPublicationsModule: StoreOptions<State> = {
  state: () => ({ ...initialState }),
  getters: {
    selectedPublicationId: (state) => {
      return state.selectedPublicationId
    },
    prevPublicationId: (state) => {
      const { publicationId } = getPreviousPublicationInfo(state.selectedPublicationId, state.data)

      return publicationId
    },

    nextPublicationId: (state) => {
      const { publicationId } = getNextPublicationInfo(state.selectedPublicationId, state.data)

      return publicationId
    },
  },
  mutations: {
    [UPDATE_SEARCH_PARAMS](state, searchParams: SearchParams) {
      state.searchParams = searchParams
    },

    [UPDATE_TOTAL_PUBLICATIONS](state, totalPublications: number) {
      state.totalPublications = totalPublications
    },

    [UPDATE_DATA](state, data: PagedPublications[]) {
      state.data = data
    },

    [UPDATE_SELECTED_PUBLICATION_ID](state, publicationId: number) {
      state.selectedPublicationId = publicationId
    },

  },
  actions: {
    [UPDATE_PREV_NEXT_PUBS_STATE](
      { commit },
      payload: UpdatePrevNextPubsParams
    ) {
      commit('UPDATE_SEARCH_PARAMS', payload.searchParams)
      commit('UPDATE_TOTAL_PUBLICATIONS', payload.results.total)
      commit('UPDATE_DATA', [{
        pageNumber: payload.searchParams.pageNumber,
        publications: payload.results.results,
      }])
    },

    async [UPDATE_PREV_NEXT_PUB_ID](
      { commit, state },
      payload
    ) {
      if (payload.publicationId !== state.selectedPublicationId) {
        commit('UPDATE_SELECTED_PUBLICATION_ID', payload.publicationId)

        fetchAndUpdatePrevPageData(commit, state)
        fetchAndUpdateNextPageData(commit, state)
      }
    },

    [RESET_PREV_NEXT_STATE]({ commit }) {
      commit('UPDATE_SEARCH_PARAMS', initialState.searchParams)
      commit('UPDATE_TOTAL_PUBLICATIONS', initialState.totalPublications)
      commit('UPDATE_DATA', initialState.data)
      commit('UPDATE_SELECTED_PUBLICATION_ID', initialState.selectedPublicationId)
    },
  },
}

export const SearchTextModule: StoreOptions<State> = {
  state: () => ({
    ...initialState,
  }),

  mutations: {
    [UPDATE_PUBLICATION_SEARCH_TEXT](state, searchText: string) {
      state.publicationsSearchText = searchText
    },

    [UPDATE_AUTHORS_SEARCH_TEXT](state, searchText: string) {
      state.authorsSearchText = searchText
    },

    [UPDATE_JOURNALS_SEARCH_TEXT](state, searchText: string) {
      state.journalsSearchText = searchText
    },

    [UPDATE_SERIES_SEARCH_TEXT](state, searchText: string) {
      state.seriesSearchText = searchText
    },

  },
}

export const AppConfigModule: StoreOptions<State> = {
  state: () => ({
    ...initialState,
  }),

  getters: {
    appConfig: (state) => {
      return state.appConfig
      // return { ...state.appConfig }
    },
  },

  mutations: {
    [UPDATE_APP_CONFIG](state, appConfig: AppConfig) {
      state.appConfig = appConfig
    },
  },
}

export const LastSearchStateModule: StoreOptions<State> = {
  state: () => ({
    ...initialState,
  }),

  getters: {
    lastSearchNotMatchingResults: (state) => {
      return state.lastSearchState.notMatchingResults
    },
    lastSearchHasErrors: (state) => {
      return state.lastSearchState.hasErrors
    },
    lastSearchErrorMessage: (state) => {
      return state.lastSearchState.errorMessage
    },
    lastSearchTypeOfItem: (state) => {
      return state.lastSearchState.typeOfItem
    },
    lastSearchShouldResetSearchParams: (state) => {
      return state.lastSearchState.shouldResetSearchParams
    },
    lastSearchIsExpandedSearch: (state) => {
      return state.lastSearchState.isExpandedSearch
    },
  },

  mutations: {
    [UPDATE_LAST_SEARCH_NOT_MATCHING](state, payload: boolean) {
      state.lastSearchState.notMatchingResults = payload
    },
    [UPDATE_LAST_SEARCH_HAS_ERRORS](state, payload: boolean) {
      state.lastSearchState.hasErrors = payload
    },
    [UPDATE_LAST_SEARCH_ERROR_MESSAGE](state, payload: string) {
      state.lastSearchState.errorMessage = payload
    },
    [UPDATE_LAST_SEARCH_TYPE_OF_ITEM](state, typeOfItem: LastSearchTypeOfItem) {
      state.lastSearchState.typeOfItem = typeOfItem
    },
    [UPDATE_LAST_SEARCH_RESET_SEARCHPARAMS](state, payload: boolean) {
      state.lastSearchState.shouldResetSearchParams = payload
    },
    [UPDATE_LAST_SEARCH_EXPANDED_SEARCH](state, expandedSearch: boolean) {
      state.lastSearchState.isExpandedSearch = expandedSearch
    },
  },

  actions: {
    [RESET_LAST_SEARCH_SEARCHPARAMS]({ commit }) {
      commit(UPDATE_LAST_SEARCH_RESET_SEARCHPARAMS, true)
      setTimeout(() => commit(UPDATE_LAST_SEARCH_RESET_SEARCHPARAMS, false), 1)
    },
    [RESET_LAST_SEARCH_STATE]({ commit }) {
      commit(UPDATE_LAST_SEARCH_NOT_MATCHING, false)
      commit(UPDATE_LAST_SEARCH_HAS_ERRORS, false)
      commit(UPDATE_LAST_SEARCH_ERROR_MESSAGE, '')
      commit(UPDATE_LAST_SEARCH_EXPANDED_SEARCH, undefined)
    },
  },
}

export const store = new Vuex.Store({
  modules: {
    ElScrollToModule,
    PrevNextPublicationsModule,
    SearchTextModule,
    AppConfigModule,
    LastSearchStateModule,
  },
})
