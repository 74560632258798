import Vue from 'vue'
import Router from 'vue-router'
import { UserListsAPI } from '@/api'
import { AppConfig } from '@/api/types'
import { AxiosError } from 'axios'

Vue.use(Router)

export function getRouter(configPromise: Promise<AppConfig>): Router {
  return new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
      {
        /* Should only exist to go to the previous version of MathSciNet, not included here */
        path: '/2006/mathscinet/',
        name: 'PreviousRelease',
      },
      {
        path: '/',
        name: 'Home',
        redirect: { name: 'PublicationsSearch' },
      },
      {
        path: '/unauthorized-access',
        name: 'UnauthorizedAccess',
        component: () => import(/* webpackChunkName: "access_error" */ './views/UnauthorizedAccess.vue'),
      },
      {
        path: '/serials/profile',
        name: 'SerialProfile',
        component: () => import(/* webpackChunkName: "serial_profile" */ './views/Profile.vue'),
      },
      {
        path: '/serials/history',
        name: 'History',
        component: () => import(/* webpackChunkName: "history" */ './views/SerialHistory.vue'),
      },
      {
        path: '/serials/volumes',
        name: 'VolumeList',
        component: () => import(/* webpackChunkName: "volumes_list" */ './views/VolumeIssueList.vue'),
      },
      {
        path: '/serials/issues',
        name: 'IssueList',
        component: () => import(/* webpackChunkName: "issues_list" */ './views/VolumeIssueList.vue'),
      },
      {
        path: '/freetools',
        name: 'FreeTools',
        redirect: { name: 'FreeToolsSearchMsc' },
      },

      //
      // SEARCH PAGE
      //
      {
        path: '/publications-search',
        name: 'PublicationsSearch',
        component: () => import(/* webpackChunkName: "publications_search" */ './views/search/PublicationsSearch.vue'),
      },
      {
        path: '/journal-search',
        name: 'JournalSearch',
        component: () => import(/* webpackChunkName: "journal_search" */ './views/search/JournalSearch.vue'),
      },
      {
        path: '/series-search',
        name: 'SeriesSearch',
        component: () => import(/* webpackChunkName: "series_search" */ './views/search/SeriesSearch.vue'),
      },
      {
        path: '/authors-search',
        name: 'AuthorsSearch',
        component: () => import(/* webpackChunkName: "authors_search" */ './views/search/AuthorsSearch.vue'),
      },
      {
        path: '/msc-search',
        name: 'SearchMsc',
        component: () => import(/* webpackChunkName: "search_msc" */ './views/freetools/MSCSearch.vue'),
      },

      //
      // DOCUMENT PAGE
      //
      {
        path: '/author',
        name: 'AuthorPage',
        component: () => import(/* webpackChunkName: "author_page" */ './views/document/AuthorPage.vue'),
      },
      {
        path: '/article',
        name: 'ArticlePage',
        component: () => import(/* webpackChunkName: "article_page" */ './views/document/ArticlePage.vue'),
      },
      {
        path: '/institution',
        name: 'InstitutionPage',
        component: () => import(/* webpackChunkName: "institution_page" */ './views/document/InstitutionPage.vue'),
      },

      //
      // FREE TOOLS
      //
      {
        path: '/freetools/msc-search',
        name: 'FreeToolsSearchMsc',
        component: () => import(/* webpackChunkName: "freetools" */ './views/freetools/MSCSearch.vue'),
      },
      {
        path: '/freetools/collab-dist',
        name: 'FreeToolsCollabDist',
        component: () => import(/* webpackChunkName: "freetools" */ './views/freetools/CollabDistance.vue'),
      },
      {
        path: '/freetools/cur-journals',
        name: 'FreeToolsCurJournals',
        component: () => import(/* webpackChunkName: "freetools" */ './views/freetools/CurrentJournals.vue'),
      },
      {
        path: '/freetools/cur-publications',
        name: 'FreeToolsCurPublications',
        component: () => import(/* webpackChunkName: "freetools" */ './views/freetools/CurrentPublications.vue'),
      },
      {
        path: '/freetools/cis',
        name: 'CIS',
        beforeEnter() { location.href = 'https://mathscinet.ams.org/cis' },
      },
      {
        path: '/freetools/mrlookup',
        name: 'MRLookup',
        component: () => import(/* webpackChunkName: "publ_mrlookup" */ './views/freetools/MRLookup.vue'),
      },
      {
        path: '/freetools/mref',
        name: 'MRef',
        component: () => import(/* webpackChunkName: "publ_mref" */ './views/freetools/MRef.vue'),
      },

      //
      // PUBLIC URLS
      //
      {
        path: '/MRAuthorID/:authorID',
        name: 'MRAuthorID',
        component: () => import(/* webpackChunkName: "publ_mrauthorid" */ './views/public/MRAuthorID.vue'),
      },
      {
        path: '/relay-station',
        name: 'RelayStation',
        component: () => import(/* webpackChunkName: "publ_relaystation" */ './views/public/RelayStation.vue'),
      },
      {
        path: '/help/resources',
        name: 'HelpLibrarians',
        component: () => import(/* webpackChunkName: "publ_helplibrarians" */ './views/public/HelpLibrarians.vue'),
      },
      {
        path: '/help/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "publ_about" */ './views/public/About.vue'),
      },
      {
        path: '/help/support',
        name: 'Support',
        component: () => import(/* webpackChunkName: "publ_support" */ './views/public/Support.vue'),
      },
      {
        path: '/help/terms_of_use',
        name: 'TermsOfUse',
        component: () => import(/* webpackChunkName: "publ_termsofuse" */ './views/public/TermsOfUse.vue'),
      },
      {
        path: '/publications',
        name: 'Publications',
        component: () => import(/* webpackChunkName: "publ_publications" */ './views/public/Publications.vue'),
      },
      {
        path: '/mr-lists/:listId',
        name: 'MRLists',
        component: async() => {
          try {
            const config = await configPromise

            if (config && (config.isDev || config.internal || (config.customerName && config.customerName !== ''))) {
              return import(/* webpackChunkName: "publications_search" */ './views/search/PublicationsSearch.vue')
            } else {
              return import(/* webpackChunkName: "publ_publications" */ './views/public/Publications.vue')
            }
          } catch (e) {
            return import(/* webpackChunkName: "publications_search" */ './views/search/PublicationsSearch.vue')
          }
        },
        async beforeEnter(to, from, next) {
          try {
            const config = await configPromise
            const list = await UserListsAPI.getSingleList(to.params.listId)

            if (config && (config.isDev || config.internal || (config.customerName && config.customerName !== ''))) {
              to.query.query = `mr:(${list.mrNumbers.join(' OR ')})`
            } else {
              to.query.mrlistId = to.params.listId
            }
          } catch (e) {
            if (e instanceof AxiosError) {
              console.error((e as AxiosError).message)
            }
          }

          next()
        },
      },

      //
      // NOT FOUND PAGE
      //
      {
        path: '*',
        name: 'NotFound',
        component: () => import(/* webpackChunkName: "not_found" */ './views/NotFound.vue'),
      },

      //
      // API TOKENS
      //
      {
        path: '/api-token',
        name: 'ApiToken',
        component: () => import(/* webpackChunkName: "api_token" */ './views/ApiToken.vue'),
      },
    ],
  })
}
