import { getResult } from './utils'
import { PromiseResults, PublicationFormat, PublicationFormatResult } from '@/global-types'
import {
  Publication,
} from '@/components/search-results/publications-results'
import { FacetsValue, PublicationFacets } from '@/components/facets'

let previousSearch: AbortController | undefined
let previousFacetsController: AbortController | undefined
let previousAuthorFacetsController: AbortController | undefined
let previousPClassificationFacetsController: AbortController | undefined
let previousJournalsFacetsController: AbortController | undefined
let previousPublicationYearFacetsController: AbortController | undefined
let previousInstitutionsFacetsController: AbortController | undefined

export const PublicationsAPI = {
  async search(query: string, currentPage: number, pageSize: number, sort?: string, order?: string, abortPrevious?: boolean): PromiseResults<Publication> {
    if (abortPrevious && previousSearch) previousSearch.abort()

    previousSearch = new AbortController()

    return getResult('/api/publications/search',
      { query, currentPage, pageSize, sort, order },
      previousSearch
    )
  },

  async searchPublic(params: {
    mi?: number,
    ps?: string,
    pc?: string,
    sc?: string,
    et?: string,
    auid?: number,
    mauid?: number,
    ri?: number,
    mrlistId?: string,
    sort?: string,
    page: number,
    size: number
  }): PromiseResults<Publication> {
    return getResult('/api/public/publications/search', {
      mi: params.mi,
      ps: params.ps,
      pc: params.pc,
      sc: params.sc,
      et: params.et,
      auid: params.auid,
      mauid: params.mauid,
      ri: params.ri,
      mrlistId: params.mrlistId,
      sort: params.sort,
      page: params.page,
      size: params.size,
    })
  },

  async getFacets(query: string, abortPrevious?: boolean): Promise<PublicationFacets> {
    if (abortPrevious && previousFacetsController) previousFacetsController.abort()

    previousFacetsController = new AbortController()

    return getResult('/api/publications/facets',
      { query },
      previousFacetsController
    )
  },

  async getAuthorsFacet(query: string, authorName?: string, authorIds?: string[]): Promise<FacetsValue[]> {
    if (previousAuthorFacetsController) previousAuthorFacetsController.abort()

    previousAuthorFacetsController = new AbortController()

    return getResult('/api/publications/authors-facet',
      {
        query,
        authorName,
        authorIds: authorIds ? authorIds.join(',').trim() : undefined,
      },
      previousAuthorFacetsController,
    )
  },

  async getJournalsFacet(query: string, text?: string, journalIds?: string[]): Promise<FacetsValue[]> {
    if (previousJournalsFacetsController) previousJournalsFacetsController.abort()

    previousJournalsFacetsController = new AbortController()

    return getResult('/api/publications/journals-facet',
      {
        query,
        text,
        journalIds: journalIds ? journalIds.join(',').trim() : undefined,
      },
      previousJournalsFacetsController,
    )
  },

  async getPrimaryClassificationsFacet(query: string, text?: string, codes?: string[]): Promise<FacetsValue[]> {
    if (previousPClassificationFacetsController) previousPClassificationFacetsController.abort()

    previousPClassificationFacetsController = new AbortController()

    return getResult('/api/publications/prim-class-facet',
      {
        query,
        text,
        codes: codes ? codes.join(',').trim() : undefined,
      },
      previousPClassificationFacetsController,
    )
  },

  async getInstitutionsFacet(query: string, names:string[]): Promise<FacetsValue[]> {
    if (previousInstitutionsFacetsController) previousInstitutionsFacetsController.abort()

    previousInstitutionsFacetsController = new AbortController()

    return getResult('/api/publications/institutions-facet',
      {
        query,
        names: names ? names.join(',').trim() : undefined,
      },
      previousInstitutionsFacetsController,
    )
  },

  async getPublicationYearFacet(query: string, year: string): Promise<FacetsValue[]> {
    if (previousPublicationYearFacetsController) previousPublicationYearFacetsController.abort()

    previousPublicationYearFacetsController = new AbortController()

    return getResult('/api/publications/pub-year-facet',
      { query, year },
      previousPublicationYearFacetsController,
    )
  },

  async getPublicationFormat(formats: PublicationFormat[], ids: number[], sort?: string, order?: string): Promise<PublicationFormatResult[]> {
    return getResult('/api/publications/format', {
      formats: formats.join(',').trim(),
      ids: ids.join(',').trim(),
      sort,
      order,
    })
  },
}
