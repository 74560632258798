import { getResult } from './utils'
import { PromiseResults, SerialMinimal } from '@/global-types'
import { Journal } from '@/components/search-results/journal-results'
import { Series } from '@/components/search-results/series-results'
import { SerialsSuggestion } from '@/components/serials-suggestions'

export const SerialsAPI = {
  searchJournals(query: string, currentPage: number, pageSize: number, sort?: string, order?: string): PromiseResults<Journal> {
    return getResult('/api/serials/journal/search', { query, currentPage, pageSize, sort, order })
  },

  searchSeries(query: string, currentPage: number, pageSize: number, sort?: string, order?: string): PromiseResults<Series> {
    return getResult('/api/serials/series/search', { query, currentPage, pageSize, sort, order })
  },

  getJournalSuggestions(title: string, totalResults: number): Promise<SerialsSuggestion[]> {
    return getResult(`/api/serials/journal/suggestions/${title}?totalResults=${totalResults}`)
  },

  getSeriesSuggestions(title: string, totalResults: number): Promise<SerialsSuggestion[]> {
    return getResult(`/api/serials/series/suggestions/${title}?totalResults=${totalResults}`)
  },

  getJournalMinimal(id: number): Promise<SerialMinimal> {
    return getResult(`/api/serials/journal/minimal/${id}`)
  },

  getSeriesMinimal(id: number): Promise<SerialMinimal> {
    return getResult(`/api/serials/series/minimal/${id}`)
  },
}
