import { getResult } from './utils'

import { Article } from '@/components/article-page'

export const ArticlesAPI = {
  async getArticle(articleId: string|number): Promise<Article | null> {
    return await getResult(`/api/articles/${encodeURIComponent(articleId)}`)
  },

  async getArticlePublic(articleId: number|string): Promise<Article | null> {
    return await getResult(`/api/public/articles/${encodeURIComponent(articleId)}`)
  },

  async getArticlePublicWithFormats(articleId: number): Promise<Article | null> {
    return await getResult(`/api/public/articles-with-formats/${articleId}`)
  },

  async getArticles(articleIds: string[]|number[], size?: number): Promise<Article[]> {
    return await getResult('/api/articles', {
      ids: articleIds.join(',').trim(),
      size,
    })
  },
}
