import Vue from 'vue'
import App from './App.vue'
import { getRouter } from './router'
import { store, UPDATE_APP_CONFIG } from './store'
import { AppConfigAPI } from '@/api'
import { setConfigCounter5, setDefaultsCounter5 } from './counter5-tracker'
import { initializeCounter5 } from './liblynx/counter5_ams.js'

Vue.config.productionTip = false

const configPromise = AppConfigAPI.getAppConfig()
const router = getRouter(configPromise)

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    MathJax: any,
    goToArticle: (mr: number) => void
    _goToArticle: (mr: number) => void
    onClickMSCHandler: (e: Event, code: string) => void
    _ll: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      push: (args: [string, any]) => void
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      shift: () => any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      unshift: (args: [string, any]) => any
      length: number
    },
    lastClickedRouterLinkInfo: {
      name: string,
    } | undefined,
    getLastClickedRouterLinkInfo(): { name: string, } | undefined,
    routerInfo: {
      to: {
        name?: string | null | undefined,
        path: string,
      },
      from: {
        name?: string | null | undefined,
        path: string,
      },
    },
  }
}

window.getLastClickedRouterLinkInfo = () => {
  const last = window.lastClickedRouterLinkInfo

  window.lastClickedRouterLinkInfo = undefined

  return last
}

window._ll = window._ll || []

const app = new Vue({
  data: {
    loading: false,
    // appConfigIsLoaded: false,
  },
  router,
  store,
  render: h => h(App),
  async created() {
    // const config = await AppConfigAPI.getAppConfig()
    const config = await configPromise
    this.$store.commit(UPDATE_APP_CONFIG, config)

    let aid = config.institution?.code && config.institution.code.trim() !== ''
      ? config.institution.code.trim().replace(/-/g, '*')
      : undefined
    let aname = config.customerName && config.customerName.trim() !== ''
      ? config.customerName
      : undefined
    let cid = config.consortium?.institution?.code && config.consortium.institution.code.trim() !== ''
      ? config.consortium.institution.code.trim().replace(/-/g, '*')
      : undefined
    let cname = config.consortium?.name && config.consortium.name.trim() !== ''
      ? config.consortium.name.trim()
      : undefined

    if (config.internal) {
      aid = 'AMS*029'
      aname = 'American Mathematical Society'
      cid = cname = undefined
    }

    // const isConfigDev = false
    const isConfigDev = config.isDev

    setConfigCounter5({ isDev: isConfigDev })
    setDefaultsCounter5({ aid, aname, cid, cname })

    // In develop mode we don't want to send any data to the server of Counter5
    // if (isConfigDev) {
    //   while (window._ll.length > 0) {
    //     console.info('COUNTER:', window._ll.shift())
    //   }

    //   window._ll.push = (args: [string, unknown]) => {
    //     console.info('COUNTER:', args)
    //   }
    // } else
    if (!aid) {
      console.warn('Counter5: Customer unknown')
    }

    if (!isConfigDev && aid) {
      initializeCounter5()
      // const counter5Script = document.createElement('script')
      // counter5Script.setAttribute(
      //   'src',
      //   config.internal ? 'https://connect.liblynx.com/log/js/counter5.js' : 'https://connect.liblynx.com/log/js/counter5.min.js'
      // )
      // counter5Script.type = 'text/javascript'
      // counter5Script.async = true
      // counter5Script.defer = true
      // document.head.appendChild(counter5Script)
    }
  },
}).$mount('#app')

router.beforeEach((to, from, next) => {
  app.loading = true
  next()
})

router.beforeResolve((to, from, next) => {
  window.routerInfo = { to, from }
  next()
})

router.afterEach(() => (app.loading = false))
