import { post } from './utils'

export const EmailAPI = {
  async sendSupportEmail(
    subject: string,
    name: string,
    email: string,
    institution: string,
    message: string
  ): Promise<void> {
    return post('/api/email/support', {
      subject,
      name,
      email,
      institution,
      message,
    })
  },
}
