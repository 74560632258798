import { getResult, post, postForm } from './utils'
import { Author, AuthorExtraInfo, AuthorSerialsIds } from '@/components/author-page'
import { Author as AuthorResult } from '@/components/search-results/authors-results'
import { PromiseResults } from '@/global-types'
import { AuthorNameSuggestion } from '@/components/author-name-suggestions'
import { AuthorFacets, FacetsValue } from '@/components/facets'
// import axios, { CancelTokenSource } from 'axios'

// let lastAuthorSuggestionRequest: CancelTokenSource

export const AuthorsAPI = {
  // async search(name: string, currentPage: number, pageSize: number, sort: string): PromiseResults<AuthorResult> {
  //   return getResult('/api/authors/search', { name, currentPage, pageSize, sort })
  // },

  async searchQuery(query: string, currentPage: number, pageSize: number, sort: string): PromiseResults<AuthorResult> {
    return getResult('/api/authors/search-query', { query, currentPage, pageSize, sort })
  },

  async getAuthor(authorId: number): Promise<Author | null> {
    return getResult(`/api/authors/${authorId}`)
  },

  async getPublicAuthor(authorId: number): Promise<Author | null> {
    return getResult(`/api/public/authors/${authorId}`)
  },

  async getAuthorSerialsIds(authorId: number): Promise<AuthorSerialsIds> {
    return getResult(`/api/authors/${authorId}/serialsIds`)
  },

  async getAuthorSuggestions(authorName: string): Promise<AuthorNameSuggestion[]> {
    // if (lastAuthorSuggestionRequest) {
    //   lastAuthorSuggestionRequest.cancel()
    // }

    // lastAuthorSuggestionRequest = axios.CancelToken.source()
    return getResult(`/api/authors/suggestions/${authorName}`)//, lastAuthorSuggestionRequest.token)
  },

  async getFacets(query: string): Promise<AuthorFacets> {
    return getResult('/api/authors/facets', { query })
  },

  async getPrimaryClassificationsFacet(query: string, text?: string, codes?: string[]): Promise<FacetsValue[]> {
    return getResult('/api/authors/prim-class-facet', {
      query,
      text,
      codes: codes ? codes.join(',').trim() : undefined,
    })
  },

  async getExtraInfo(authorId: number): Promise<AuthorExtraInfo> {
    return getResult(`/api/authors/${authorId}/extra-info`)
  },

  async updateExtraInfo(
    authorId: number, nameInNativeScript: string,
    email: string, website: string,
  ): Promise<AuthorExtraInfo> {
    return post('/api/authors/update-extra-info', {
      authorId,
      nameInNativeScript,
      email,
      website,
    })
  },

  async uploadPicture(authorId: number, picture: File): Promise<void> {
    const formData = new FormData()
    formData.append('picture', picture)

    return postForm(`/api/authors/${authorId}/upload-picture`, formData)
  },

  async deletePicture(authorId: number): Promise<void> {
    return post(`/api/authors/${authorId}/delete-picture`, {})
  },

}
