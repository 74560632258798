import { ApiTokenUserInfoResponse, ApiTokenResponse } from './types'
import { getResult, post } from './utils'

export const ApiTokenAPI = {

  async generateToken(reasonOfUse: string): Promise<ApiTokenResponse> {
    return post('/api/api-token', {
      reasonOfUse,
    })
  },

  async getUserInfo(): Promise<ApiTokenUserInfoResponse> {
    return getResult('/api/token-user-info')
  },

}
