<template>
  <!-- <BContainer
    fluid="xl"
    class="h-100"
  > -->
  <BContainer
    fluid="lg"
    class="h-100 p-0"
  >
    <Header />

    <main id="main-content">
      <div v-if="$root.loading">
        <div
          class="text-center text-ams-orange"
        >
          <BSpinner />
        </div>
      </div>

      <RouterView v-else />
    </main>

    <Footer />
  </BContainer>
</template>

<script>
import { Vue, Component } from 'vue-property-decorator'
import Header from './Header'
import Footer from './Footer'

@Component({
  components: {
    Header,
    Footer,
  },
})
export default class Layout extends Vue {
}
</script>
