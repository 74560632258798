
import { Vue, Component, Watch } from 'vue-property-decorator'
import { AMSAccountInfo, PairingInfo } from '@/api/types'
import { AppConfigAPI, AMSAccountAPI } from '@/api'

type HeaderRoute = {
  text?: string
  name?: string
  altName?: string
  extIcon?: string
  href?: string
  target?: string
}

@Component
export default class Header extends Vue {
  //
  // REACTIVE PROPERTIES
  //
  logoHeaderUrl = ''
  logoRemoteAccessUrl = ''
  logoAmsDayUrl = ''
  loadingAMSInfo = true
  isLoggedInAMSAccount = false
  amsAccountInfo: AMSAccountInfo | null = null
  routes: HeaderRoute[] = [
    { text: 'Home', name: 'Home', altName: 'PublicationsSearch' },
    { text: 'Resources', name: 'HelpLibrarians' },
    { text: 'Reviewers', href: '/mresubs/index.html', target: '_blank' },
    { text: 'Free Tools', name: 'FreeTools', altName: 'FreeToolsSearchMsc' },
    { text: 'Support', name: 'Support' },
    { text: 'Help Pages', href: '/mathscinet/info/index.html', target: '_blank' },
  ]

  selectedComponentName = ''
  pairingInfo: PairingInfo = {
    active: false,
    daysLeft: 0,
  }

  amsdaytest = false
  showIP = false

  //
  // WATCHERS
  //
  // @Watch('$route')
  // onRouteChanged() {
  //   this.selectedComponentName = this.$route.name || ''
  // }

  @Watch('$route', { immediate: true })
  async refreshTabLocation() {
    this.selectedComponentName = this.$route.name || ''

    this.amsdaytest = this.$route.query.amsdaytest !== undefined

    if (this.$route.query.myip) {
      this.showIP = true
    }

    // Return focus to the top of the new page.
    try {
      (this.$refs.preSkipLink as HTMLElement).focus()
      if (document.activeElement) {
        (document.activeElement as HTMLElement).blur() // Removes the focus
      }
      (this.$refs.preSkipLink as HTMLElement).tabIndex = -1 // Disable the preSkipLink from being able to have focus
    } catch {
      // Was getting "TypeError: Cannot read properties of undefined (reading 'focus')"
      // I'm assuming it was being run twice on the same page, like when a # is present in the URL
    }
  }

  //
  // COMPUTED PROPERTIES
  //
  get remoteAccessURL() {
    return this.$store.state.AppConfigModule.appConfig.remoteAccessURL
  }

  get daysLeftTitle() {
    return this.pairingInfo.daysLeft === 1
      ? '1 day left before Remote Access expires'
      : `${this.pairingInfo.daysLeft} days left before Remote Access expires`
  }

  get customerName() {
    return this.$store.state.AppConfigModule.appConfig.customerName
  }

  get showAmsDayMessage() {
    return this.amsdaytest || (this.$store.getters.appConfig.isAmsDay && this.$store.getters.appConfig.institution === null)
  }

  get showAmsDayWelcomeMessage() {
    return this.amsdaytest || (this.$store.getters.appConfig.isAmsDay && this.isLoggedInAMSAccount)
  }

  get showRemoteAccess() {
    // return this.$store.getters.appConfig.institution !== null // Caused confusion for customers not on site, but knew they needed Remote Access
    return true
  }

  get isAMSMember() {
    return this.amsAccountInfo?.isMember
  }

  get ipAddresses() {
    return {
      clientIP: this.$store.state.AppConfigModule.appConfig.clientIP,
      serverIP: this.$store.state.AppConfigModule.appConfig.serverIP,
    }
  }

  //
  // LIFE CYCLE HOOKS
  //

  async mounted() {
    this.checkIfAMSLoggedInAndMember()

    // Loads logo dynamically
    import('@/assets/logo.png').then(r => (this.logoHeaderUrl = r.default))
    import('@/assets/remote-access-icon.png').then(r => (this.logoRemoteAccessUrl = r.default))
    import('@/assets/ams-day-logo.png').then(r => (this.logoAmsDayUrl = r.default))

    this.pairingInfo = await AppConfigAPI.getPairingInfo()
  }

  //
  // METHODS
  //
  onClickAMSDayLogin() {
    const url = window.location.href
    AMSAccountAPI.redirectToAMSLogin(url)
  }

  async checkIfAMSLoggedInAndMember() {
    this.loadingAMSInfo = true
    this.isLoggedInAMSAccount = await AMSAccountAPI.isLoggedIn()
    this.amsAccountInfo = await AMSAccountAPI.getAMSAccountInfo()
    this.loadingAMSInfo = false
  }
}
